export const experienceData = [
  {
    "type":"work",
    "about":null,
    "years":"June 2019 - Present",
    "location_city":"Dublin, Ireland",
    "role_name":"Data Scientist",
    "responsibilities":"I help to drive business and marketing strategy through building causal inference test frameworks, implementing data storage solutions for GoogleAds data, and predicting customer value. I also provide analytics support for various departments throughout the company.",
    "name":"HostelWorld",
  },
  {
    "type":"work",
    "about":null,
    "years":"Aug 2019 - June 2020",
    "location_city":"Remote (London / Dublin)",
    "role_name":"Data Scientist",
    "responsibilities":"Developed sentiment analysis models + acted as data / AI strategist  helping develop company whitepapers and contributing to pitch decks for client bids.",
    "name":"Crossing Technologies",
  },
  {
    "type":"work",
    "about":null,
    "years":"Sep 2017 – Jun 2018",
    "location_city":"Dublin, Ireland",
    "role_name":"Postdoctoral Researcher (Nanoscience)",
    "responsibilities":"My research interests were in additive manufacturing (printed / sprayed electronics) specifically using cutting edge nanomaterials and not-so cutting edge household printers. ",
    "name":"CRANN & AMBER Institute, Trinity College Dublin"
  },
  {
    "type":"school",
    "about":null,
    "years":"Sep 2013 - Sep 2017",
    "location_city":"Trinity College Dublin | Dublin, Ireland",
    "role_name": "PhD Researcer",
    "responsibilities":"My research focused on mixing 2 dimensional nanomaterials with carbon nanotubes to create catalysts for splitting water into hydrogen and oxygen. The main applications for this technology was cheaper renewable energy storage, and fule production for hydro-electric vehicles.",
    "name":"PhD Researcher (Nanoscience)",
    "thesis_name": "Nanostructured electrodes as catalysts for the water splitting reaction",
    "thesis_link":"http://www.tara.tcd.ie/handle/2262/82351"

  },
  {
    "type":"work",
    "about":null,
    "years":"Sep 2016 - Sep 2017",
    "location_city":"Wake Forest | North Carolina, USA",
    "role_name":"Guest Researcher",
    "responsibilities":"Playing with polymers and carbon nanotubes to create white light emitting devices (similar to LEDs) for room lighting applications.",
    "name":"Wake Forest University, Nanotechnology Centre",
  },
  {
    "type":"school",
    "about":null,
    "years":"Sep 2009 - Sep 2013",
    "location_city":"Trinity College | Dublin, Ireland",
    "responsibilities":"Graduated with first class honours (equiv. 3.6 - 4.0 GPA) from Trinity College Dublin with a bachelor's degree in physics.",
    "name":"BA (Hons) Physics",
    "thesis_name": "White Conversion Techniques in Organic Field-Induced Polymer Electroluminescence Device",
    "thesis_link":null
  },
];
