export const datageneralSkillsData = [
  {
    "name":"Python",
    "filled": [],
    "outlined": []
  },
  {
    "name":"R",
    "filled": [],
    "outlined": []
  },
  {
    "name":"SQL",
    "filled": [],
    "outlined": []
  },
  
  
];
 
export const datavisSkillsData = [
  {
    "name":"Tableau",
    "filled": [],
    "outlined": []
  },
  {
    "name":"Excel",
    "filled": [],
    "outlined": []
  },
  {
    "name":"Git",
    "filled": [],
    "outlined": []
  },
  {
    "name":"OriginPro",
    "filled": [],
    "outlined": []
  },
  
];

export const mlSkillsData = [
  {
    "name":"sklearn",
    "filled": [0,1,2,3],
    "outlined": [4]
  },
  {
    "name":"word2vec",
    "filled": [0,1,2],
    "outlined": [3,4]
  },
  {
    "name":"keras",
    "filled": [0,1],
    "outlined": [2,3,4]
  },
  {
    "name":"TensorFlow",
    "filled": [0,1],
    "outlined": [2,3,4]
  },
];

export const otherSkillsData = [
  {
    "name":"Git",
    "filled": [0,1],
    "outlined": [2,3,4]
  },
  {
    "name":"GoogleAds",
    "filled": [0,1,2],
    "outlined": [3,4]
  },
  {
    "name":"Latex",
    "filled": [0,1,2],
    "outlined": [3,4]
  },
 
];