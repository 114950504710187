export const aboutData = [
  {
    "title":"Hi, I'm David",
    "blurb":"I'm a former nanoscientist turned data scientist, currently working in the customer insights team at HostelWorld in sunny Dublin,Ireland."
  },
  {
    "title":"Professional Interests",
    "blurb":"I love a good data deepdive, fitting models to predict customer behavour, or simply discovering insights and using them to inform business strategy and decision making."
  },
  {
    "title":"Personal Interests",
    "blurb":"I'm always in the mood for learning something new, whether it's through reading about Irish history, listening to a philosophy podcast, watching random Youtube videos or just chatting with friends about current events. I also love to travel, experience new cultures and eat crazy foods."
  },
];
